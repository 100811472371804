import React from "react";
import { InboxDialogContent } from "~/shadcn/components/ui/inbox_dialog";
import { Dialog } from "~/shadcn/components/ui/dialog";
import { Link2Icon, Share1Icon } from "@radix-ui/react-icons";
import { ToolbarButton } from "../ui/ToolbarButton";
import { Insight } from "~/models/Insight";
import CategoryTypePill from "../ui/CategoryTypePill";
import { PageEventTypePill } from "../ui/PageEventTypePill";
import { URLSummaryCell } from "../ui/URLSummaryCell";
import { Separator } from "~/shadcn/components/ui/separator";
import { useNavigate } from "react-router-dom";
import { Company } from "~/models/Company";
import { CompanyLogo } from "../ui/CompanyLogo";
import { toast } from "sonner";

interface RenderToolbarButtonProps {
  listenKey: string;
  onClick: () => void;
  icon: React.ReactNode;
  text: string;
  shortcut: string;
}

function RenderToolbarButton({
  listenKey,
  onClick,
  icon,
  text,
  shortcut,
}: RenderToolbarButtonProps) {
  return (
    <ToolbarButton listenKey={listenKey} onClick={onClick}>
      <div className="flex items-center gap-2">
        {icon}
        <div className="text-xs font-semibold">{text}</div>
        <div className="text-xs font-medium text-slate-500">{shortcut}</div>
      </div>
    </ToolbarButton>
  );
}

export interface HighlightModalViewProps {
  company?: Company;
  insight: Insight;
  isDialogOpen: boolean;
  setIsDialogOpen: (open: boolean) => void;
}

export const HighlightModalView = ({
  company,
  insight,
  isDialogOpen,
  setIsDialogOpen,
}: HighlightModalViewProps) => {
  const navigate = useNavigate();
  const sources = insight.sources || [];

  return (
    <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
      <InboxDialogContent className="bg-clear border-0 shadow-none w-[750px]">
        <div className="flex flex-col h-full w-full gap-4">
          <div className="flex flex-col gap-4 bg-white rounded-lg shadow-lg border pt-4 w-full overflow-y-auto h-[600px]">
            <div className="flex flex-col gap-2 px-4">
              <div className="flex items-center gap-2">
                <CompanyLogo url={company?.url} />
                <div className="text-slate-500 text-sm">{insight?.company_name}</div>
              </div>
              <div className="font-medium mb-2">{insight?.title}</div>
              {insight?.category && <CategoryTypePill category={insight?.category} />}
            </div>

            <Separator />

            <div className="px-4 mb-8 h-full">{insight?.description}</div>

            <div className="px-4 text-xs text-slate-500">Sources</div>

            <div className="px-4 flex flex-col gap-2 mb-4">
              {sources.map((source, index) => {
                if (source) {
                  return (
                    <button
                      key={index}
                      onClick={() => {
                        if (source.tracked_page_id) {
                          navigate({
                            pathname: `/page-changes/${source.tracked_page_id}`,
                          });
                        } else {
                          window.open(source.url, "_blank");
                        }
                      }}
                    >
                      <div className="flex gap-4 items-center mb-2">
                        <PageEventTypePill type={source.kind} />
                        <URLSummaryCell url={source.url} description={source.summary} />
                      </div>
                      {index < insight.sources.length - 1 && <Separator />}
                    </button>
                  );
                } else {
                  return null;
                }
              })}
            </div>
          </div>

          <div className="flex gap-4 bg-white rounded-lg shadow-lg border h-fit p-4">
            <RenderToolbarButton
              listenKey="c"
              onClick={() => {
                navigator.clipboard.writeText(
                  `${insight.title}\n${insight.description}\n${insight.sources
                    .map((source) => source.url)
                    .join("\n")}`,
                );
                toast("📋  Copied to clipboard");
              }}
              icon={<Link2Icon className="w-4 h-4 text-blue-500" />}
              text="Copy"
              shortcut="C"
            />
            <div className="w-full" />
          </div>
        </div>
      </InboxDialogContent>
    </Dialog>
  );
};
